$font-branch: "Quicksand", sans-serif;
$font-normal: "Roboto","Arial",sans-serif;

$header-height: 5rem;

$transition-zero: 0.25s cubic-bezier(0.68, -0.55, 0.27, 1.55) all;
$transition-one: .2s ease all;
$transition-two: 0.2s cubic-bezier(0, 0, 0.2, 1) all;

$dark-bg-base: #121212;
$dark-bg-base-tranparent: #12121233;
$dark-bg-highlight: #1a1a1a;
$dark-bg-press: #000;
$dark-bg-elevated-base: #242424;
$dark-bg-elevated-highlight: #2a2a2a;
$dark-bg-elevated-press: #000;
$dark-bg-upper-base: #363636;
$dark-bg-upper-highlight: #3a3a3a;
$dark-bg-upper-press: #000;
$dark-text-base: #fff;
$dark-text-subdued: #a7a7a7;
$dark-text-highlight: #e2e2e2;
$dark-text-base-highlight: #d9d9d9;
$dark-text-negative: #f15e6c;
$dark-text-positive: #1ed760;
$dark-decorative-base: #fff;
$dark-decorative-subdued: #292929;
$dark-decorative-highlight: #393939;
$dark-decorative-highlight-press: #dadada;
$dark-button-text-primary: #66b2ff;
$dark-button-bg-primary: #66b2ff;
$dark-button-bg-primary-hover: #223c55;

$light-button-bg-primary: #e9f9f9;
$light-button-bg-primary-hover: #e0f0f0;

$light-backdrop: rgba(0,0,0,.25);
$light-bg-base: #fff;
$light-bg-base-tranparent: #ffffffde;
$light-bg-highlight: #f9f9f9;
$light-bg-press: #FFF;
$light-bg-elevated-base: #ececec;
$light-bg-elevated-highlight: #d9d9d9;
$light-bg-elevated-press: #FFF;
$light-bg-upper-base: #d1d1d1;
$light-bg-upper-highlight: #d9d9d9;
$light-bg-upper-press: #FFF;
$light-text-base: #000;
$light-text-subdued: #5a5a5a;
$light-text-highlight: #121212;
$light-text-base-highlight: #6a6a6a;
$light-text-info: #0b57d0;
$light-text-negative: #d32f2f;
$light-text-positive: #1ed760;
$light-decorative-base: #000;
$light-decorative-subdued: #dadada;
$light-decorative-highlight: #393939;
$light-decorative-highlight-press: #dadada;
$light-button-bg-primary: #e9f9f9;
$light-button-bg-primary-hover: #e0f0f0;