@use './variables' as v;

*{
    box-sizing: border-box;
    background: transparent;
    outline: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
    scroll-behavior: smooth;
    font-family: v.$font-normal;
    font-size: 16px;
    color: currentColor;
}
*::selection{
    background: #000;
    color: #FFF;
}
body{
    font-family: v.$font-branch;
    background: v.$light-bg-press;
}
.svg{
    width: 1em;
    font-size: 1em;
    fill: currentColor;
    color: currentColor;
    display: inline-block;
    path{
        color: currentColor;
        fill: currentColor;
    }
}
img{
    width: 100%;
}
.desing{
    background: rgb(111,197,255);
    background: linear-gradient(120deg, rgba(111,197,255,1) 0%, rgba(127,255,127,1) 100%);
}
.infrastructure{
    background: rgb(139,0,0);
    background: linear-gradient(99deg, rgba(139,0,0,1) 0%, rgba(255,69,0,1) 100%);
}
.maintenance{
    background: rgb(180,118,96);
    background: linear-gradient(99deg, rgba(180,118,96,1) 0%, rgba(8,0,255,1) 100%);
}
.MuiTooltip-tooltip {
    background: #000 !important;
    margin-top: .5rem !important;
    font-family: v.$font-branch !important;
    font-weight: bold !important;
    font-size: .8rem !important;
}
.list{
    &.style{
        &-circle{
            display: flex;
            flex-flow: column;
            list-style: none;
            .list-image{
                font-size: .325rem;
            }
            > li{
                margin-bottom: .25rem;
                a, button {
                    display: flex;
                    align-items: center;
                    gap: .75rem;
                    padding: 0 .5rem;
                    margin-left: -.55rem;
                    width: max-content;
                    span{
                        margin-top: 2px;
                    }
                }
            }
        }
    }
}
.font{
    &.p{
        &-small{
            font-size: .875rem;
            color: v.$light-text-subdued;
            &.variant{
                &-negative{
                    font-weight: 500;
                }
            }
        }
    }
    &.weight{
        &-bold{
            font-weight: bold;
        }
    }
    &.variant{
        &-negative{
            color: v.$light-text-negative;
        }
    }
    &.align{
        &-center{
            text-align: center;
        }
    }
    span {
        font-size: inherit;
        color: inherit;
    }
    
    &.branch{
        font-family: v.$font-branch;
        font-weight: bold;
    }
}
.input-msg{
    font-weight: 500;
    line-height: 1.325;
    font-size: .865rem;
    color: v.$light-text-subdued;
    &.complete{
        text-decoration: line-through;
    }
    &.error{
        color: v.$light-text-negative;
    }
}
.hide{
    visibility: hidden;
    opacity: 0;
    position: absolute;
}
span.progress,
.progress{
    &.circular{
        color: currentColor;
        width: 1em;
        height: 1em;
        font-size: 1em;
    }
    &.linear{
        background: transparent;
        span {
            background: v.$dark-bg-base;
        }
    }
}
.notistack-SnackbarContainer{
    > div{
        > div {
            > div {
                > div {
                    background: v.$dark-bg-base;
                    color: v.$dark-text-base;
                }
            }
        }
    }
}
.MuiAvatar-root{
    background: v.$light-bg-elevated-base;
}
.full{
    &-width{
        width: 100%;
    }
}
input.input,
.input{
    &.with-endAdornment{
        .MuiInputBase-root{
            padding-right: 0;
        }
    }
    &.default{
        fieldset{
            border-color: #000;
            border-width: 2px;
        }
        .MuiInputBase-root{
            border-radius: 4px;
        }
    }
}
button.btn,
a.btn,
.btn{
    text-transform: initial;
    &:disabled{
        opacity: .6;
    }
    &.light,
    &.high,
    &.medium{
        display: flex;
        font-size: 0.875rem;
        align-items: center;
        gap: .5rem;
        width: max-content;
        padding: .5rem 1rem;
        border-radius: 100px;
        transition: v.$transition-one;
    }
    &.light{
        &:hover{
            color: v.$light-text-base;
            background: v.$light-bg-elevated-base;
        }
    }
    &.medium{
        background: v.$light-button-bg-primary;
        &:hover{
            background: v.$light-button-bg-primary-hover;
        }
    }
    &.high{
        background: v.$dark-bg-base;
        color: v.$dark-text-base;
        margin: -2px;
        border: 2px solid v.$light-bg-base;
        outline: 2px solid transparent;
        &:hover{
            outline: 2px solid v.$dark-bg-base;
        }
    }
    &.modal-high{
        display: flex;
        font-size: 0.875rem;
        align-items: center;
        background: v.$light-button-bg-primary;
        flex: 1;
        gap: .5rem;
        width: max-content;
        padding: .5rem 1rem;
        border-radius: 100px;
        transition: v.$transition-one;
        &:hover{
            background: v.$light-button-bg-primary-hover;
        }
    }
    &.text{
        text-transform: none;
        text-decoration: underline !important;
        color: v.$light-text-base;
        &-lite{
            min-width: 0;
            min-height: 0;
            border-radius: 100px;
            color: v.$light-text-info;
            font-weight: 500;
            line-height: 1.25;
            display: inline-block;
            letter-spacing: 0.015625rem;
            font-size: 1em;
            padding: 0 .25rem;
            margin: 0 -.25rem;
            text-transform: initial;
            outline: 0;
            vertical-align: auto;
            transition: v.$transition-one;
            display: inline-flex;
            align-items: center;
            gap: .125rem;
            svg{
                font-size: 1em;
            }
            &:focus,
            &:hover{
                background: rgba(11, 87, 208, .1);
            }
        }
    }
    &.card {
        display: flex;
        padding-bottom: 1px;
        position: relative;
        &:nth-last-child(1){
            &::after{
                display: none;
            }
        }
        &::after{
            border-bottom: 1px solid v.$light-decorative-subdued;
            content: "";
            height: 0;
            left: 0;
            position: absolute;
            width: 100%;
            bottom: 0;
        }
        .card-link{
            -webkit-box-flex: 1;
            -webkit-flex-grow: 1;
            flex-grow: 1;
            padding-bottom: .55rem;
            padding-top: .55rem;
            margin-left: -1rem;
            margin-right: -1rem;
            padding-left: .5rem;
            padding-right: .5rem;
            border-radius: 1rem;
            display: flex;
            text-align: initial;
            text-transform: initial;
            color: v.$light-text-base-highlight;
            &_layout{
                width: 100%;
                > div {
                    display: flex;
                    -webkit-box-align: center;
                    -webkit-align-items: center;
                    align-items: center;
                    .avatar{
                        -webkit-box-flex: 0;
                        -webkit-flex: none;
                        flex: none;
                        width: 2.5rem;
                        height: 2.5rem;
                        margin-right: .5rem;
                        > div{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .icon{
                        width: 2.5rem;
                        height: 2.5rem;
                        margin-right: .25rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        svg{
                            font-size: 1.75em;
                        }
                    }
                    .body{
                        -webkit-box-flex: 1;
                        -webkit-flex-grow: 1;
                        flex-grow: 1;
                        .name{
                            color: v.$light-text-subdued;
                            font-family: v.$font-branch;
                            font-size: 1rem;
                            font-weight: bold;
                            letter-spacing: 0rem;
                            line-height: 1.5;
                        }
                        .email{
                            color: v.$light-text-subdued;
                            font-size: .875rem;
                            font-weight: 400;
                            letter-spacing: 0rem;
                            line-height: 1.4285714286;
                        }
                    }
                }
            }
        }
    } 
    &.avatar{
        padding: .25rem;
        width: 1em;
        height: 1em;
        background: v.$light-bg-highlight;
        &:hover{
            background: v.$light-bg-elevated-base;
            .banner{
                z-index: 0;
            }
        }
        .MuiAvatar-root{
            width: 100%;
            height: 100%;
        }
        .banner{
            position: absolute;
            z-index: -1;
            font-size: calc(1em / 4);
            background: v.$light-bg-base;
            padding: .5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            box-shadow: 0 0 10px v.$light-backdrop;
            svg {
                font-size: 1em;
            }
        }
    }
    &.default{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.25rem;
        color: v.$light-text-base;
        padding: .25rem 1rem;
        border: 2px solid #fff;
        outline: 2px solid #000;
        border-radius: 100px;
        transition: v.$transition-two;
        gap: .25rem;
        &.colors-inverse{
            background: v.$dark-bg-base;
            color: v.$dark-text-base;
            &:hover{
                transform: translateY(-5px);
            }
        }
        span{
            font-family: v.$font-branch;
            font-weight: bold;
            color: currentColor;
            font-size: .8em;
        }
        &:hover{
            background: #000;
            color: #Fff;
        }
    }
    &.project{
        flex-flow: column;
        border: 1px solid v.$light-decorative-subdued;
        flex: 1;
        color: v.$light-text-base;
        font-family: v.$font-branch;
        font-weight: 600;
        text-transform: none;
        &.active{
            background: v.$dark-bg-base;
            color: v.$dark-text-base;
            border-color: v.$dark-text-base;
            outline: 2px solid v.$light-text-base;
        }
    }
}
.flex{
    display: flex;
    &.justify{
        &-end{
            justify-content: flex-end;
        }
        &-around{
            justify-content: space-around;
        }
        &-between{
            justify-content: space-between;
        }
        &-evenly{
            justify-content: space-evenly;
        }
    }
    &.align{
        &-center{
            align-items: center;
        }
    }
    &.flow{
        &-column{
            flex-flow: column;
        }
    }
}