@use '../../../styles/variables' as v;
.account-chooser_layout{
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    padding: 0;
    justify-content: space-between;
    min-height: 100vh;
    background-color: v.$light-bg-base;
    .account {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        margin: 0 auto;
        background: v.$light-bg-base;
        position: relative;
        transition: v.$transition-one;
        overflow: hidden;
        &.loading,
        .account-inherit.loading{
            opacity: 1;
            pointer-events: none;
            user-select: none;
            .account_banner{
                &::after{
                    opacity: .3;
                    visibility: visible;
                }
            }
        }
        .account-inherit{
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            transition: v.$transition-one;
            .account_banner{
                &.isYuga{
                    padding-top: 3rem;
                }
                &::after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: v.$light-bg-base;
                    z-index: 2;
                    opacity: 0;
                    visibility: hidden;
                    top: 0;
                    left: 0;
                    transition: v.$transition-one;
                }
                .progress{
                    top: .25rem;
                    z-index: 1;
                    left: 1.5rem;
                    width: calc(100% - 1.5rem * 2);
                    border-radius: 100px;
                    mix-blend-mode: difference;
                    &.linear{
                        span{
                            background: v.$light-bg-base !important;
                        }
                    }
                }
            }
        }
        .account-inherit > div,
        form > div {    
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-bottom: 1.5rem;
            &.isYuga{
                padding-top: .5rem;
            }
        }
        &_banner{
            padding-top: calc(1.5rem + calc(1.5rem + 1.5rem));
            /* margin-top: calc(calc(3rem) * -1); */
            background: v.$dark-bg-base;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            color: v.$dark-text-base;
            padding-bottom: 1rem;
            .progress{
                position: fixed;
                width: 100%;
                left: 0;
                top: 0;
            }
            .brand{
                display: flex;
                -webkit-box-pack: start;
                -webkit-justify-content: flex-start;
                justify-content: flex-start;
                height: 4rem;
                > .logo{
                    display: flex;
                    justify-content: flex-start;
                    height: 4rem;
                    align-items: flex-end;
                    .MuiAvatar-root {
                        background: v.$dark-bg-elevated-base;
                    }
                    h2{
                        color: v.$dark-text-base;
                        font-weight: 400;
                        line-height: 1.25;
                        word-break: break-word;
                        font-size: 1.75rem;
                        margin: auto .5rem .375rem;
                    }
                    svg{
                        width: auto;
                        height: 100%;
                    }
                }
                &_layout{
                    mix-blend-mode: difference;
                    display: flex;
                    -webkit-box-align: center;
                    -webkit-align-items: center;
                    align-items: center;
                    left: 0;
                    padding: 1rem 1rem;
                    position: absolute;
                    right: 0;
                    top: 0;
                    .logo{
                        height: 1rem;
                        margin-right: .75rem;
                        font-size: 1rem;
                        .svg{
                            width: auto;
                            height: 1em;
                        }
                    }
                    .text{
                        color: v.$dark-text-highlight;
                        font-size: 1rem;
                        height: 1rem;
                        margin-top: -1px;
                        font-weight: 500;
                    }
                }
            }
            .body{
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                flex-direction: column;
                margin-bottom: 1rem;
                > div {
                    color: v.$dark-text-highlight;
                    font-weight: 400;
                    letter-spacing: 0;
                    line-height: 1.5;
                    margin-bottom: 0;
                    margin-top: 1rem;
                    font-size: 1rem;
                }
                .btn.text-lite{
                    color: v.$dark-button-text-primary;
                    &:hover{
                        background: v.$dark-button-bg-primary-hover;
                    }
                }
            }
        }
            
        h1{
            color: v.$light-text-base;
            font-weight: 400;
            line-height: 1.25;
            word-break: break-word;
            font-size: 2rem;
            margin: 1rem 0 1.5rem;
            &.account-choose{
                margin-bottom: .25rem;
                ~ .session-owner {
                    margin-bottom: 1.5rem;
                }
            }
        }
        .session-owner {
            display: flex;
            align-items: center;
            width: max-content;
            color: v.$light-text-subdued;
            width: 100%;
            height: 1.5rem;
            line-height: 0;
            font-size: .85rem;
            svg {
                font-size: 1rem;
                margin-right: .25rem;
            }
            > span {
                min-width: 150px;
                height: 100%;
            }
        }
        .account_card {
            display: flex;
            padding-bottom: 1px;
            position: relative;
            &::after{
                border-bottom: 1px solid v.$light-decorative-subdued;
                content: "";
                height: 0;
                left: 0;
                position: absolute;
                width: 100%;
                bottom: 0;
            }
            &-link{
                -webkit-box-flex: 1;
                -webkit-flex-grow: 1;
                flex-grow: 1;
                padding-bottom: .75rem;
                padding-top: .75rem;
                margin-left: -1rem;
                margin-right: -1rem;
                padding-left: 1rem;
                padding-right: 1rem;
                border-radius: 1rem;
                display: flex;
                text-align: initial;
                text-transform: initial;
                color: v.$light-text-base-highlight;
                &_layout{
                    width: 100%;
                    > div {
                        display: flex;
                        -webkit-box-align: center;
                        -webkit-align-items: center;
                        align-items: center;
                        .avatar{
                            -webkit-box-flex: 0;
                            -webkit-flex: none;
                            flex: none;
                            width: 2.5rem;
                            height: 2.5rem;
                            margin-right: .75rem;
                            > div{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .icon{
                            width: 2.5rem;
                            height: 2.5rem;
                            margin-right: .75rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            svg{
                                font-size: 1.75em;
                            }
                        }
                        .body{
                            -webkit-box-flex: 1;
                            -webkit-flex-grow: 1;
                            flex-grow: 1;
                            .name{
                                color: v.$light-text-base;
                                font-family: v.$font-branch;
                                font-size: 1rem;
                                font-weight: bold;
                                letter-spacing: 0rem;
                                line-height: 1.5;
                            }
                            .email{
                                color: v.$light-text-subdued;
                                font-size: .875rem;
                                font-weight: 400;
                                letter-spacing: 0rem;
                                line-height: 1.4285714286;
                            }
                        }
                    }
                }
            }
        }
        &-chooser_footer{
            color: v.$light-text-subdued;
            font-size: .875rem;
            line-height: 1.4285714286;
            margin-top: 2rem;
            > div { 
                font-size: inherit;
            }
        }
    }
    > .account-chooser_footer{
        margin: 0 auto;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        width: 100%;
        > div {
            footer {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                > ul {
                    display: flex;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    > li {
                        display: flex;
                        margin: 1rem .25rem;
                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 600px) {
    .account-chooser_layout {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        padding: 3rem 0;
        background-color: v.$light-bg-highlight;
        background-image: url(https://i.ibb.co/qJLyftC/abstract-maze-design-pattern-background-2303.jpg);
        background-size: 4rem;
        .account{
            border-radius: 1.75rem;
            border: 1px solid v.$light-decorative-subdued;
            min-height: 528px;
            width: 480px;
            .account-inherit{
                min-height: calc(528px - 1.5rem - calc(1.5rem + calc(3rem + 1.5rem)));
            }
            form > div {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
            &_banner{
                .progress{
                    position: absolute;
                }
            }
        }
        > .account-chooser_footer{
            width: 500px;
        }
    }
}

@media (min-width: 919px) {
    .account-chooser_layout {
        .account{
            width: 80%;
            max-width: 840px;
            flex-flow: row wrap;
            min-height: 384px;
            .account-inherit{
                flex-flow: row wrap;
                min-height: calc(384px - 1.5rem - 6rem);
            }
            .account_banner{
                flex-basis: 50%;
                flex-grow: 1;
                max-width: 50%;
                padding-right: 1.5rem;
                ~ div{
                    flex-basis: 50%;
                    flex-grow: 1;
                    max-width: 50%;
                    padding-left: 1.5rem;
                    padding-top: 4.5rem;
                }
                .brand{
                    &_layout{
                        background: v.$dark-bg-base;
                    }
                }
                ~ .isYuga{
                    padding-top: 3rem !important;
                }
            }
            h1{
                margin-top: 0;
            }
            .loading{
                height: auto !important;
            }
            div.buttons{
                width: 100%;
                gap: 1rem;
                flex-basis: initial;
                flex-grow: initial;
                max-width: initial;
                padding-left: 0;
                margin: 0 !important;
                height: max-content;
                justify-content: flex-end;
            }
        }
        > .account-chooser_footer{
            width: calc(80% + 20px);
            max-width: 860px;
        }
    }
}